<template>
  <el-dialog
    class="dorm-build-dialog"
    :title="`${preTitle}宿舍楼${title}`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="720px"
    :before-close="cancel">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="140px"
    >
      <!--      {{userInfo.managerList[userInfo.managerList.length-1].userId}}-->
      <el-row>
        <el-col :span="12">
          <el-form-item label="所在校区" prop="campusId">
            <el-select v-model="formData.campusId" @change="getSort('campus')">
              <el-option
                v-for="{campusName, id} in campusList" :label="campusName"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="宿舍楼" prop="dormitoryName">
            <el-input v-model.trim="formData.dormitoryName" @change="getSort('dormitory',$event)" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序" prop="sort">
            <tool-tips-group is-flex
                             tips-content="第一位为校区：校本部=1，南校区=2；<br/>第二、三位为楼栋：1栋=01，16栋=16；<br/>第四、五位为预留,默认00；<br/>例：<br/>校本部1-1栋=10101；<br/>南校区2栋=20200；<br/>校本部16栋A=11601<br/>">
              <el-input v-model.trim="formData.sort" />
            </tool-tips-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="宿舍性别" prop="sexType">
            <el-select v-model="formData.sexType">
              <el-option
                v-for="{name,type} in sexType"
                :label="name"
                :value="type"
                :key="type" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="楼层总数" prop="floorAmount">
            <el-input-number :min="1" :max="66" v-model.trim="formData.floorAmount" />
          </el-form-item>
        </el-col>
        <el-col :span="12"></el-col>
        <el-col :span="12"></el-col>
        <el-col :span="12"></el-col>
        <el-col :span="12"></el-col>
      </el-row>
      <el-form-item label="宿舍管理员(可多选)">
        <tool-tips-group is-flex tips-content="名字搜索最少输入2个汉字。<br/>
                         身份证/录取编号搜索最少输入6位数字。<br/>
                         <b class='font-danger'>教职工搜索只显示在住的教职工。</b><br/>">
          <!--   <br/>只能搜索所管辖学院的学生。  <span>搜索学生：</span>-->
          <el-autocomplete
            v-model="userInfo.value"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入教职工姓名、身份证、工号"
            size="small"
            clearable
            @select="handleSelect" style="display: inline-block;" />
        </tool-tips-group>
      </el-form-item>
      <el-form-item v-if="userInfo.managerList" label="" prop="createTime">
        <el-tag v-for="tag in userInfo.managerList" :key="tag.id"
                closable style="margin-right: 10px;"
                @close="removeTag(tag)">
          {{ tag.realName }}
        </el-tag>
      </el-form-item>
      <el-form-item v-if="id" label="创建时间" prop="createTime">
        <tool-tips-group tips-content="不能为空，通过修改创建时间，对数据进行排序。">
          <el-date-picker
            v-model="formData.createTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          />
        </tool-tips-group>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addDormBuild, getDormBuildById } from '@/api/dorm'
import dialog from '@/vue/mixins/dialog'
// import { getUserAll } from '@/api/system/user-api'
import userAutocomplete from '@/vue/mixins/user-autocomplete'

export default {
  name: 'dormBuildDialog',
  mixins: [dialog, userAutocomplete],
  data() {
    return {
      formData: {
        id: '',
        campusId: '', // 校区
        sexType: 1, // 宿舍性别（1——男，2——女）
        floorAmount: '', // 楼层数量
        dormitoryName: '', // 宿舍名称
        // managerName: '', // 宿管 单个（弃用 改多选）
        // managerPhone: '', // 宿管电话 单个（弃用 改多选）
        // managerUserId: '', // 宿管用户id 单个（弃用 改多选）
        managerUserIds: [], // 宿管用户id 支持绑定多个 (save字段)
        managerUsers: [], // 宿管用户已绑定的教职工信息list
        createTime: '',
        sort: '0',
        remarks: '',
        userType: 3 // 用于远程搜索教职工
      },
      // 远程搜索结果
      userInfo: {
        value: '',
        managerList: []
      },
      sexType: [],
      campusList: [],
      rules: {
        dormitoryName: [
          {
            required: true,
            message: '请输入宿舍楼名称',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit20WordsObj
        ],
        campusId: [{
          required: true,
          message: '请选择校区',
          trigger: 'blur'
        }],
        floorAmount: this.$store.state.commonValidate.validatorNumberObj,
        managerPhone: this.$store.state.commonValidate.validatorPhoneObj,
        sort: {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              return callback(new Error('排序码为必填!'))
            } else if (!/^\d+$/.test(value)) {
              return callback(new Error('仅支持纯数字'))
            } else if (value.toString().length !== 5) {
              return callback(new Error('排序码必须为5位数!'))
            } else {
              return callback()
            }
          },
          trigger: 'blur'
        }
      }
    }
  },
  async mounted() {
    await this.getCampusList()
    this.sexType = this.$store.state.systemParam.sexType

    await this.$nextTick()
    if (this.id) {
      await this.getDataById(this.id, getDormBuildById)
      this.userInfo.managerList = this.formData.managerUsers
    } else {
      this.setInitData()
    }

    // 用于远程搜索教职工
    this.formData.userType = 3
  },
  watch: {
    'formData.createTime': function(newVal, oldVal) {
      if (!newVal) {
        this.$message.warning('创建时间不能为空!')
        this.formData.createTime = oldVal
      }
    }
  },
  methods: {
    addDormBuild, // 保存api
    // 获取校区
    async getCampusList() {
      try {
        const res = await this.$http.getCampusListQuery({})
        this.campusList = res.data
      } catch (e) {
      }
    },
    // 选项
    handleSelect(val) {
      console.log('ddd', val)
      const hasExist = this.userInfo.managerList.find(manager => manager.id === val.id)
      if (hasExist) {
        this.$message.warning('该用户已存在')
      } else {
        this.userInfo.managerList.push(val)
      }
    },
    // 移除宿舍管理员
    removeTag(tag) {
      this.userInfo.managerList = this.userInfo.managerList.filter(manager => manager.id !== tag.id)
    },
    getCampus(id) {
      return this.campusList.find(item => item.id === id)
    },
    getSort(type, val) {
      const letters = '0ABCDEFGHI'
      // 截取字符串的 数字-数字
      const getEnDash = (numString) => {
        let numStr = numString.match(/\d+-\d+/)
        if (numStr) numStr = numStr[0]
        return numStr
      }
      this.$nextTick(() => {
        // 改变校区时
        const campusInfo = this.getCampus(this.formData.campusId)
        let originSort = this.formData.sort + ''
        if (campusInfo?.campusNo) {
          // /./=第一位
          originSort = originSort.replace(/./, campusInfo.campusNo)
        }
        if (type === 'campus') {
          this.formData.sort = originSort
        } else if (type === 'dormitory') {
          // 获取楼栋编号
          let dormInfo = val.split('区')
          dormInfo = !dormInfo[1] ? dormInfo[0] : dormInfo[dormInfo.length - 1]
          if (dormInfo) {
            // 第二、三位
            let dormNum = '00'
            // 最后两位
            let subNum = '00'
            // 获取带连接号的楼栋信息,如'18-1'
            const dashNum = getEnDash(dormInfo)
            // 1.获取第二、第三位编码
            if (dashNum) {
              // 存在带连接号'-'时，直接获取'-'之前的数字
              dormNum = dashNum.split('-')[0]
            } else if (dormInfo.includes('栋')) {
              dormNum = dormInfo.split('栋')[0].match(/\d+/)[0]
            }
            // 个位数时，给第二位 补零
            if (dormNum.length < 2) {
              dormNum = '0' + dormNum
            }
            // 2. 获取最后两位编码，存在字母时
            const letterIndex = letters.indexOf(dormInfo[dormInfo.length - 1].toUpperCase())
            if (letterIndex > 0) {
              if (dashNum) {
                // 3.带‘-’时，斜杠后的数字在倒数第二位，字母为最后一位
                subNum = dashNum[dashNum.length - 1] + letterIndex
              } else {
                // 4.不带‘-’时，倒数第二位变为0
                subNum = '0' + letterIndex
              }
            } else if (dashNum) {
              // 5.不存在字母,但存在连接号’-‘
              subNum = dashNum.split('-')[1]
              if (subNum.length < 2) {
                subNum = '0' + subNum
              }
            }
            this.formData.sort = originSort.substring(0, 1) + dormNum + subNum
            this.$refs.form.validateField('sort')
          }
        }
      })
    },
    async handleSave() {
      const campusInfo = this.getCampus(this.formData.campusId)
      this.formData.managerUserIds = []
      // 清空无用的信息，减轻服务器压力
      this.formData.managerUsers = null
      if (this.userInfo.managerList.length) {
        console.log(this.userInfo.managerList)
        this.formData.managerUserIds = this.userInfo.managerList.map(item => item.userId || item.id)
      }
      if ((this.formData.sort + '')[0] !== (campusInfo.campusNo + '')) {
        this.$message.error('排序码与校区不匹配!(校本部-1，南校区-2)')
        return false
      }
      await this.save('宿舍楼', addDormBuild)
    }
  }
}
</script>
