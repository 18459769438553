<template>
  <table-view v-loading="loading" :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
    <template #header>
      <view-search-form
        :queryInfo.sync="queryInfo"
        :tool-list="['campus','major','keyword']"
        style="display: inline-block;"
        @on-search="renderTable(1)" />
      <div class="header-button fr">
        <upload-excel :permit-key="['dorm:build:edit']"
                      :loading.sync="loading"
                      :api="$http.importExcelHttp.importDormBuild"
                      @on-download="$http.exportExcelHttp.exportDormBuildTemplate()"
                      @on-success="renderTable(1)" />
        <el-button
          v-permission="['dorm:build:edit']"
          type="primary"
          size="small"
          @click="showDialog()"
        >添加宿舍楼
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="campusName" label="所在校区" width="120" />
      <el-table-column prop="dormitoryName" label="宿舍楼" width="150" />
      <el-table-column prop="floorAmount" label="楼层总数" width="100" align="center" />
      <el-table-column prop="sexType" label="宿舍性别" width="100" align="center">
        <template v-slot="{row}">
          {{ row.sexType === 1 ? '男' : (row.sexType === 2 ? '女' : '') }}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间(用于排序)" width="160" />
      <el-table-column prop="createTime" label="宿舍管理员" width="320" >
        <template v-slot="{row}">
          <template v-if="row.managers">
            <el-tag v-for="(item,index) in row.managers.split(',')" class="mt-1 mr-1" :key="index">{{item}}</el-tag>
          </template>
        </template>
      </el-table-column>
      <!--<el-table-column prop="managerName" label="宿舍管理员" width="100" />-->
      <!--<el-table-column prop="managerPhone" label="联系电话" width="120" />-->
      <el-table-column prop="remarks" label="备注" min-width="150" />
      <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="{row}">
          <el-button v-permission="['dorm:build:edit']" type="primary" size="mini"
                     @click="showDialog(row.id,`-${row.dormitoryName}`)">编辑
          </el-button>
          <el-button
            v-permission="['dorm:build:del']"
            size="mini"
            type="danger"
            @click="del(deleteDormBuild, row.id, `宿舍楼-${row.dormitoryName}`)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <dorm-build-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { deleteDormBuild, getDormBuildList } from '@/api/dorm'
import tableView from '@/vue/mixins/table-view'
import dormBuildDialog from '@/views/pages/dormManage/dorm-build/component/dormBuildDialog.vue'
import major from '@/views/pages/baseInfo/major/index.vue'

export default {
  computed: {
    major() {
      return major
    }
  },
  components: {
    dormBuildDialog
  },
  mixins: [tableView],
  data() {
    return {
      queryInfo: {
        campusId: null,
        majorId: null
      }
    }
  },
  methods: {
    deleteDormBuild, // 删除api
    // 获取宿舍楼列表
    renderTable(pageNum) {
      this.getTableData(getDormBuildList, pageNum)
    }
    // 获取新闻列表
    // async renderTable() {
    // this.loading = true
    // try {
    //   const res = await getDormBuildList(this.queryInfo)
    //   this.tableData = res.data
    // } catch (e) {
    //
    // } finally {
    //   this.loading = false
    // }
    // }
  }
}
</script>
